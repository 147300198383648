import { Modal, ModalFuncProps, Select, Spin } from 'antd';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import styled from 'styled-components';

import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { TManageAccounts } from '@app/types/manageAccounts';
import { useEffect } from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getEnumValue } from '@app/services/enum.service';

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FormTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 25%;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const CreateButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export type TManageAccountsForm = {
  name: string;
  microsoftTenantId: string;
  microsoftTenantName: string;
  microsoftApplicationId: string;
  microsoftApplicationSecret: string;
  ownerEmail: string;
  status: number;
};

interface IManageAccountsProps extends ModalFuncProps {
  isEdit?: boolean;
  isLoading?: boolean;
  account?: TManageAccounts;
}

export default function ManageAccountsForm({ isEdit, isLoading, account, ...modalProps }: IManageAccountsProps) {
  const accountStatusOptions = useAppSelector((state) => (state.app.appValues as any)?.AccountStatus) as {
    value: number;
    label: string;
  }[];

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<TManageAccountsForm>({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (modalProps.open && !!account) {
      reset({
        name: account?.name,
        microsoftTenantId: account?.microsoftTenantId,
        microsoftTenantName: account?.microsoftTenantName,
        microsoftApplicationId: account?.microsoftApplicationId,
        microsoftApplicationSecret: account?.microsoftApplicationSecret,
        ownerEmail: account?.ownerEmail,
        status: getEnumValue('AccountStatus', account.status as any),
      });
    } else if (modalProps.open && !account) {
      reset({
        name: '',
        microsoftTenantId: '',
        microsoftTenantName: '',
        microsoftApplicationId: '',
        microsoftApplicationSecret: '',
        ownerEmail: '',
        status: getEnumValue('AccountStatus', 'Enabled'),
      });
    }
  }, [modalProps.open, account]);

  const onSubmit: SubmitHandler<TManageAccountsForm> = (data) => {
    modalProps.onOk?.(data);
  };

  const handleCancel = () => {
    reset();
    modalProps.onCancel?.();
  };

  return (
    <Modal
      style={{
        minWidth: '600px',
      }}
      {...modalProps}
      destroyOnClose={true}
      closable={false}
      footer={null}
    >
      <Container onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>{`${isEdit ? 'Edit' : 'Create'} Account`}</FormTitle>
        <FormContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Name:</FieldLabel>
            <InputContainer>
              <FieldInput hasError={!!errors.name} {...register('name', { required: true })} />
              {!!errors.name && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Microsoft Tenant ID:</FieldLabel>
            <InputContainer>
              <FieldInput
                hasError={!!errors.microsoftTenantId}
                {...register('microsoftTenantId', { required: false })}
              />
              {!!errors.microsoftTenantId && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Microsoft Onmicrosoft Domain:</FieldLabel>
            <InputContainer>
              <FieldInput
                hasError={!!errors.microsoftTenantName}
                {...register('microsoftTenantName', { required: false })}
              />
              {!!errors.microsoftTenantName && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>

          <HorizontalAlignedContainer>
            <FieldLabel>Microsoft Application ID:</FieldLabel>
            <InputContainer>
              <FieldInput
                hasError={!!errors.microsoftApplicationId}
                {...register('microsoftApplicationId', { required: false })}
              />
              {!!errors.microsoftApplicationId && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Microsoft Application Secret:</FieldLabel>
            <InputContainer>
              <FieldInput
                type="password"
                hasError={!!errors.microsoftApplicationSecret}
                {...register('microsoftApplicationSecret', { required: false })}
              />
              {!!errors.microsoftApplicationSecret && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Owner Email:</FieldLabel>
            <InputContainer>
              <FieldInput
                type="email"
                hasError={!!errors.ownerEmail}
                {...register('ownerEmail', {
                  required: 'This field is required',
                  pattern: {
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: 'Invalid email address',
                  },
                })}
              />
              {!!errors.ownerEmail && <ErrorMessage>{errors.ownerEmail.message}</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Status:</FieldLabel>
            <InputContainer>
              <Controller
                control={control}
                name="status"
                render={({ field }) => (
                  <Select
                    {...field}
                    value={field.value}
                    showSearch
                    style={{ flexBasis: '75%' }}
                    options={accountStatusOptions}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                  >
                    {accountStatusOptions.map((opt) => (
                      <Select.Option key={opt.value} value={opt.value}>
                        {opt.label}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              />
              {!!errors.status && <ErrorMessage>{errors.status.message}</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
        </FormContainer>
        <HorizontalAlignedContainer
          style={{
            gap: '10px',
            justifyContent: 'center',
          }}
        >
          {isLoading ? (
            <Spin />
          ) : (
            <>
              <CancelButton type="button" onClick={handleCancel}>
                Cancel
              </CancelButton>
              <CreateButton>{isEdit ? 'Update' : 'Create'}</CreateButton>
            </>
          )}
        </HorizontalAlignedContainer>
      </Container>
    </Modal>
  );
}
