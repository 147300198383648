import { useEffect, useMemo, useState } from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { usePagination } from './usePagination';
import { useExportToExcel, useGetTableModelItems } from '@app/api/table.api';
import { TDataColumns, TFilterValue } from '../types';
import { TList } from '@app/types/generalTypes';
import { TActionItems, useTableFilters } from './useTableFilters';
import { useMounted } from '@app/hooks/useMounted';
import { convertSelectUrlToColumns, replaceSpecialChars } from '@app/utils/utils';

const TOP_PARAMS_KEY = '$top';
const SKIP_PARAMS_KEY = '$skip';
const FILTER_PARAMS_KEY = '$filter';
const SELECT_PARAMS_KEY = '$select';
const ADDED_FILTERS_KEY = '$added';
const ORDER_BY_PARAMS_KEY = '$navorder';

const DEFAULT_LIMIT_INTERVAL = [10, 25, 50];
const DEFAULT_SKIP = '0';
const DEFAULT_LIMIT = '10';
const DEFAULT_IS_MOCK = false;
const DEFAULT_TOTAL_ROWS = 0;

type TQueryParams = {
  top?: number;
  skip?: number;
  filterValues: TFilterValue[];
  orderBy?: {
    column: string;
    order: 'asc' | 'desc' | string;
  };
  expand?: string[];
};

interface IUseDataTableProps<T extends { id: number | string }> {
  model?: string;
  endpoint?: string;
  customQueryKey?: string;
  queryFn?: (queryString: string) => Promise<TList<T>>;
  columns: TDataColumns<T>;
  // if true, then the table does not read/write URL query params
  noUrl?: boolean;
  limit?: number;
  skip?: number;
  limitIntervals?: number[];
  isMock?: boolean;
  maxHeight?: number;
  searchableColumns?: string[];
  constantFilter?: string;
  defaultOrder?: TQueryParams['orderBy'];
  expand?: TQueryParams['expand'];
  actionItems?: TActionItems<T>[];
  trigger?: boolean;
  hideExportToExcel?: boolean;
  showHeader?: boolean;
  showTimeline?: boolean;
  emptyRowText?: string;
  stickyHeader?: boolean;
  onTimelineRangeChanged?: (newRange: { startDate: Date; endDate: Date } | undefined) => void;
  onFetch?: (response: TList<T>) => void;
  onRowClick?: (data: T, index: number | undefined) => void;
  onRowMouseEnter?: (data: T, index: number | undefined) => void;
  onRowMouseLeave?: (data: T, index: number | undefined) => void;
  onSelectRows?: (selectedRows: T[]) => void;
  onFiltersChangeCallback?: (filters: TFilterValue[]) => void;
}

export function useDataTable<T extends { id: number | string }>({
  model,
  endpoint,
  customQueryKey,
  queryFn,
  columns,
  noUrl = false,
  limit = noUrl
    ? parseInt(DEFAULT_LIMIT)
    : parseInt(new URLSearchParams(window.location.search).get(TOP_PARAMS_KEY) || DEFAULT_LIMIT),
  skip = noUrl
    ? parseInt(DEFAULT_SKIP)
    : parseInt(new URLSearchParams(window.location.search).get(SKIP_PARAMS_KEY) || DEFAULT_SKIP),
  limitIntervals = DEFAULT_LIMIT_INTERVAL,
  isMock = DEFAULT_IS_MOCK,
  maxHeight,
  searchableColumns,
  constantFilter,
  defaultOrder,
  actionItems,
  trigger,
  hideExportToExcel,
  showHeader,
  showTimeline,
  emptyRowText,
  stickyHeader,
  onRowClick,
  onRowMouseEnter,
  onRowMouseLeave,
  onSelectRows,
  onFetch,
  onFiltersChangeCallback,
  onTimelineRangeChanged,
  expand,
}: IUseDataTableProps<T>) {
  const navigate = useNavigate();
  const { mutateAsync: exportToExcel } = useExportToExcel();
  const { isMounted } = useMounted();
  const { state } = useLocation();

  const defaultFilterValues = useMemo(() => {
    if (
      noUrl ||
      ((!new URLSearchParams(window.location.search).get(FILTER_PARAMS_KEY) ||
        new URLSearchParams(window.location.search).get(FILTER_PARAMS_KEY) === '') &&
        (!new URLSearchParams(window.location.search).get(ADDED_FILTERS_KEY) ||
          new URLSearchParams(window.location.search).get(ADDED_FILTERS_KEY) === ''))
    ) {
      return undefined;
    }

    const filterValues =
      (new URLSearchParams(window.location.search)
        .get(FILTER_PARAMS_KEY)
        ?.replaceAll('contains', '')
        ?.replaceAll('tolower', '')
        ?.split(/[()]+/)
        .join('')
        .split(' and ')
        .map((filter) => {
          const key = filter.replace('contains', '').replace(/['()]/g, '').replaceAll(' ', ',').split(',')[0];
          const filterCol = columns.find((col) => col.dataIndex === key);
          let value = filter.replace(/[()]/g, '').split(' eq ')[1];

          if ((filterCol as any).type === 'enumArray') {
            const ids = filter.replace(/[()]/g, '').match(/\d+/g);
            value = JSON.stringify(Array.from(new Set(ids)));
          }

          if ((filterCol as any).type === 'enum') {
            const enumValues = filter.split(' or ').map((splitFilter) => splitFilter.split(' eq ').at(-1)?.trim());
            value = JSON.stringify(Array.from(new Set(enumValues)));
          }

          if ((filterCol as any).type === 'text') {
            value = filter.replace(/[()]/g, '').split(',').slice(1).join().slice(1, -1);
          }

          if ((filterCol as any).type === 'textOptions') {
            if ((filterCol as any).multipleSelect) {
              const regex = /'(.*?)'/g;
              const matches = [];
              let match;
              while ((match = regex.exec(filter.replace(/[()]/g, ''))) !== null) {
                matches.push(match[1]);
              }
              value = JSON.stringify(matches);
            } else {
              value = filter.replace(/[()]/g, '').split(',').slice(1).join().slice(1, -1);
            }
          }

          if ((filterCol as any).type === 'date') {
            value = JSON.stringify(filter.split(' and ').map((dateFilter) => dateFilter.split(' ')[2]));
          }

          return {
            title: typeof filterCol?.title === 'string' ? filterCol?.title : filterCol?.altLabel,
            column: filterCol?.dataIndex,
            type: (filterCol as any).type,
            value,
            ...(((filterCol as any).type === 'enum' || (filterCol as any).type === 'enumArray') && {
              enumKey: (filterCol as any).enumValuesKey,
            }),
            ...((filterCol as any).type === 'textOptions' && {
              options: (filterCol as any).options,
              multipleSelect: !!(filterCol as any).multipleSelect,
            }),
            ...((filterCol as any).type === 'boolean' && {
              hasNullOption: (filterCol as any).hasNullOption,
            }),
          };
        }) as TFilterValue[]) || [];

    const addedFilters = JSON.parse(
      (new URLSearchParams(window.location.search).get(ADDED_FILTERS_KEY) as string) || '[]',
    ) as unknown as TFilterValue[];

    filterValues.push(...addedFilters);

    return filterValues.sort((a, b) => a.title!.localeCompare(b!.title as string));
  }, [window.location.search]);

  const defaultSelectValues = useMemo(() => {
    if (noUrl) {
      return columns.filter((col) => !col.hideColumn);
    }

    if (new URLSearchParams(window.location.search).get(SELECT_PARAMS_KEY) === '') {
      return undefined;
    }

    return convertSelectUrlToColumns(columns);
  }, [window.location.search]);

  const defaultOrderBy = useMemo(() => {
    if (new URLSearchParams(window.location.search).get(ORDER_BY_PARAMS_KEY) === '') {
      return undefined;
    }

    return JSON.parse(new URLSearchParams(window.location.search).get(ORDER_BY_PARAMS_KEY)!) as TQueryParams['orderBy'];
  }, [window.location.search]);

  const [columnsState, setColumnsState] = useState(columns);
  const [queryParams, setQueryParams] = useState<TQueryParams>({
    top: limit,
    skip: skip,
    orderBy: defaultOrder || defaultOrderBy || undefined,
    filterValues: defaultFilterValues || [],
    expand: expand || undefined,
  });
  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  const andtDTableCols = useMemo(() => {
    return columnsState
      .filter((col) => col.hideColumn !== true)
      .map((col) => {
        let newColumn = { ...col };

        if (col.showSortDirections) {
          newColumn = { ...col, sorter: (a: any, b: any) => 0 };
        } else {
          newColumn = { ...col, sorter: false };
        }

        return newColumn;
      });
  }, [columnsState]);

  const hasFilters = useMemo(() => {
    return columnsState.filter((col) => col.allowFiltering === true).length > 0;
  }, [columnsState]);

  const exposedQueryString = useMemo(() => {
    const { top, skip, orderBy, filterValues, expand } = queryParams;

    const allFilters = filterValues
      .filter((f) => f.type !== 'searchableColumn')
      .sort((a, b) => (a.type === 'text' ? -1 : 1))
      .map((f) => {
        if (f.type === 'number') return `(${f.column} ${f.value})`;

        if (f.type === 'boolean') return `(${f.column} eq ${f.value})`;

        if (f.type === 'enum') {
          let value: number[] = JSON.parse(f.value);
          value = (Array.isArray(value) ? value : [value]) as number[];
          return `(${value.map((val) => `${f.column} eq ${val}`).join(' or ')})`;
        }

        if (f.type === 'textOptions' && !!f.multipleSelect) {
          return `(${(JSON.parse(f.value) as string[]).map((val) => `contains(${f.column}, '${val}')`).join(' or ')})`;
        }

        if (f.type === 'enumArray') {
          return `(${(JSON.parse(f.value) as number[])
            .map((val) => `(contains(${f.column},'-${val}-'))`)
            .join(' or ')})`;
        }

        if (f.type === 'date') {
          const dates = JSON.parse(f.value);
          return `(${f.column} ge ${format(dates[0], 'yyyy-MM-dd')} and ${f.column} le ${format(
            dates[1],
            'yyyy-MM-dd',
          )})`;
        }

        return `(contains(tolower(${f.column}),'${replaceSpecialChars(f.value.toLowerCase().replaceAll("''", "'"))}'))`;
      })
      .join(' and ');

    const concatenatedFilters = [allFilters].filter((f) => f !== '').join(' and ');

    const shownColumns = columnsState
      .filter((col) => (col.dataIndex !== undefined || col.dataIndex !== undefined) && !col.hideColumn)
      .map((col) => col.dataIndex)
      .join(',');

    const queryString = `${filterValues.length > 0 ? `$filter=${concatenatedFilters}` : ''}${
      orderBy ? `&$orderby=${orderBy.column} ${orderBy.order}` : ''
    }${
      filterValues.length === 0 && !orderBy ? `$select=${shownColumns}` : `&$select=${shownColumns}`
    }&$top=${top}&$skip=${skip}${expand && expand.length > 0 ? `&$expand=${expand.join(',')}` : ''}`;

    return queryString;
  }, [queryParams, columnsState]);

  const queryString = useMemo(() => {
    const { top, skip, orderBy, filterValues, expand } = queryParams;

    const allFilters = filterValues
      .filter((f) => f.type !== 'searchableColumn')
      .sort((a, b) => (a.type === 'text' ? -1 : 1))
      .map((f) => {
        if (f.type === 'number') return `(${f.column} ${f.value})`;

        if (f.type === 'boolean') return `(${f.column} eq ${f.value})`;

        if (f.type === 'enum') {
          let value: number[] = JSON.parse(f.value);
          value = (Array.isArray(value) ? value : [value]) as number[];
          return `(${(value as number[]).map((val) => `${f.column} eq ${val}`).join(' or ')})`;
        }

        if (f.type === 'enumArray') {
          return `(${(JSON.parse(f.value) as number[]).map((val) => `contains(${f.column},'-${val}-')`).join(' or ')})`;
        }

        if (f.type === 'textOptions' && !!f.multipleSelect) {
          return `(${(JSON.parse(f.value) as string[]).map((val) => `contains(${f.column}, '${val}')`).join(' or ')})`;
        }

        if (f.type === 'date') {
          const dates = JSON.parse(f.value);
          return `(${f.column} ge ${format(dates[0], 'yyyy-MM-dd')} and ${f.column} le ${format(
            dates[1],
            'yyyy-MM-dd',
          )})`;
        }

        return `(contains(tolower(${f.column}),'${replaceSpecialChars(f.value.toLowerCase().replaceAll("''", "'"))}'))`;
      })
      .join(' and ');

    const concatenatedFilters = [allFilters, constantFilter].filter((f) => f !== '' && f !== undefined).join(' and ');

    return `${concatenatedFilters !== '' ? `$filter=${concatenatedFilters}&` : ''}$top=${top}&$skip=${skip}${
      orderBy ? `&$orderby=${orderBy.column} ${orderBy.order}` : ''
    }${expand && expand.length > 0 ? `&$expand=${expand.join(',')}` : ''}`;
  }, [queryParams, constantFilter]);

  const filtersOnlyQueryString = useMemo(() => {
    const { filterValues } = queryParams;

    const allFilters = filterValues
      .filter((f) => f.type !== 'searchableColumn')
      .sort((a, b) => (a.type === 'text' ? -1 : 1))
      .map((f) => {
        if (f.type === 'number') return `(${f.column} ${f.value})`;

        if (f.type === 'boolean') return `(${f.column} eq ${f.value})`;

        if (f.type === 'enumArray') {
          return `(${(JSON.parse(f.value) as number[]).map((val) => `contains(${f.column},'-${val}-')`).join(' or ')})`;
        }

        if (f.type === 'enum') {
          let value: number[] = JSON.parse(f.value);
          value = (Array.isArray(value) ? value : [value]) as number[];

          return `(${(value as number[]).map((val) => `${f.column} eq ${val}`).join(' or ')})`;
        }

        if (f.type === 'textOptions' && !!f.multipleSelect) {
          return `(${(JSON.parse(f.value) as string[]).map((val) => `contains(${f.column}, '${val}')`).join(' or ')})`;
        }

        if (f.type === 'date') {
          const dates = JSON.parse(f.value);
          return `(${f.column} ge ${format(dates[0], 'yyyy-MM-dd')} and ${f.column} le ${format(
            dates[1],
            'yyyy-MM-dd',
          )})`;
        }

        return `(contains(tolower(${f.column}),'${replaceSpecialChars(f.value.toLowerCase().replaceAll("''", "'"))}'))`;
      })
      .join(' and ');

    const concatenatedFilters = [allFilters, constantFilter].filter((f) => f !== '' && f !== undefined).join(' and ');

    return `${concatenatedFilters !== '' ? `$filter=${concatenatedFilters}` : ''}`;
  }, [queryParams, constantFilter]);

  const searchableColumnQueryString = useMemo(() => {
    const { filterValues } = queryParams;
    const searchableColumnsFilters = filterValues
      .filter((f) => f.type === 'searchableColumn')
      .map((f) => `(contains(tolower(${f.column}),'${f.value.toLowerCase()}'))`)
      .join(' or ');

    if (searchableColumnsFilters === '') {
      return undefined;
    }

    return searchableColumnsFilters;
  }, [queryParams]);

  const {
    data,
    refetch: getItems,
    isFetching,
  } = useGetTableModelItems({
    model,
    endpoint,
    customQueryKey,
    queryFn,
    columns,
    queryParams: queryString,
    searchQueryParam: searchableColumnQueryString,
    trigger,
  });

  const { resetToDefaults, ...paginationProps } = usePagination({
    totalRows: DEFAULT_TOTAL_ROWS,
    limit,
    skip,
    limitIntervals,
    onNext: ({ limit, skip }) => {
      setQueryParams({ ...queryParams, top: limit, skip: skip });
    },
    onPrev: ({ limit, skip }) => {
      setQueryParams({ ...queryParams, top: limit, skip: skip });
    },
    onPageClicked: ({ limit, skip }) => {
      setQueryParams({ ...queryParams, top: limit, skip: skip });
    },
    onLimitChanged: ({ limit, skip }) => {
      setQueryParams({ ...queryParams, top: limit, skip: skip });
    },
  });

  const filterProps = useTableFilters({
    columns: columnsState,
    defaultFilter: defaultFilterValues,
    defaultCheckedColumns: defaultSelectValues as any,
    selectedRows,
    actionItems,
    hideExportToExcel,
    showTimeline,
    onFiltersChanged,
    onTimelineRangeChanged,
    onColumnsChanged: (newCols) => setColumnsState(newCols),
    onSearch: searchableColumns && searchableColumns.length > 0 ? handleSearch : undefined,
    onExport: handleExport,
  });

  const onSort = (sortProps: SorterResult<T>) => {
    if (!sortProps.order) {
      setQueryParams({
        ...queryParams,
        orderBy: undefined,
      });
      return;
    }

    setQueryParams({
      ...queryParams,
      orderBy: {
        column: sortProps.field?.toString() || '',
        order: sortProps.order === 'ascend' ? 'asc' : 'desc',
      },
    });
  };

  function onFiltersChanged(
    filters: TFilterValue[],
    orderBy?: { column: string; order: 'asc' | 'desc' },
    filterString?: string,
  ) {
    resetToDefaults();
    const searchableColumnsFilters = queryParams.filterValues.filter((f) => f.type === 'searchableColumn');
    const newQueryParams = {
      ...queryParams,
      top: limit,
      skip: 0, // force to page 1
      filterValues: [...searchableColumnsFilters, ...filters],
      ...(orderBy && { orderBy }),
    };
    setQueryParams(newQueryParams);

    let newColumnState = [...columnsState];

    if (orderBy) {
      newColumnState = newColumnState.map((col) => {
        if (col.dataIndex === orderBy?.column) {
          return {
            ...col,
            defaultSortOrder: orderBy?.order === 'asc' ? 'ascend' : 'descend',
          };
        }

        return col;
      }) as TDataColumns<T>;
    }

    if (filterString) {
      const selectParamArray = filterString
        .replace('$filter=', '')
        .split(/\sand\s|\&\$/)
        .find((f) => f.includes('select'))
        ?.replace('select=', '')
        .split(',');

      newColumnState = newColumnState.map((col) => {
        if (!selectParamArray?.includes(col.dataIndex as string)) {
          return { ...col, hideColumn: true };
        }

        return col;
      });
    }

    setColumnsState(newColumnState);
    onFiltersChangeCallback?.(filters);
  }

  function handleSearch(searchValue: string) {
    if (searchValue === '') {
      const newFilterValues = queryParams.filterValues.filter((f) => f.type !== 'searchableColumn');
      setQueryParams({ ...queryParams, filterValues: newFilterValues });
      return;
    }

    if (searchValue.length <= 2) {
      return;
    }

    /**
     * odata server cant handle single quotation marks on the search.
     * as a solution, we append another single quotation mark so the search works.
     */
    const modifiedSearchValue = replaceSpecialChars(searchValue);

    const filterValues = [...queryParams.filterValues];

    for (const col of searchableColumns!) {
      const colIndex = filterValues.findIndex((fv) => fv.column === col && fv.type === 'searchableColumn');

      if (colIndex > -1) {
        filterValues[colIndex] = { ...filterValues[colIndex], value: modifiedSearchValue };
      } else {
        filterValues.push({
          value: modifiedSearchValue,
          column: col,
          type: 'searchableColumn',
        });
      }
    }

    setQueryParams({ ...queryParams, filterValues });
  }

  async function handleExport() {
    const selectedFields = columnsState
      .filter((col) => !!col.dataIndex && !col.hideColumn)
      .map((col) => ({
        name: col.dataIndex,
        displayName: col.title,
      })) as { name: string; displayName: string }[];

    const response = await exportToExcel({
      modelName: model as string,
      filters: !!filtersOnlyQueryString ? filtersOnlyQueryString.replace('$filter=', '') : undefined,
      sortOrder: queryParams.orderBy?.column
        ? {
            fieldName: queryParams.orderBy?.column as string,
            order: queryParams.orderBy?.order as 'asc' | 'desc',
          }
        : undefined,
      selectedFields,
    });
  }

  function clearSelection() {
    setSelectedRows([]);
  }

  function changeColumns(newCols: TDataColumns<T>) {
    setColumnsState(newCols);
  }

  useEffect(() => {
    getItems();
  }, [queryParams, columnsState]);

  useEffect(() => {
    if (data) {
      onFetch?.(data);
      paginationProps.setTotalRows(data.count);
    }
  }, [data]);

  useEffect(() => {
    if (defaultSelectValues && defaultSelectValues?.length > 0) {
      const defaultSelectKeys = defaultSelectValues.map((val) => val?.dataIndex);
      const newColumnState = columnsState.map((col) => {
        return { ...col, hideColumn: col.dataIndex && !defaultSelectKeys.includes(col.dataIndex) ? true : false };
      });
      setColumnsState(newColumnState);
    }
  }, []);

  useEffect(() => {
    if (!defaultFilterValues) return;

    setQueryParams({ ...queryParams, filterValues: defaultFilterValues });
  }, [defaultFilterValues]);

  // useEffect(() => {
  //   const isForced = state?.force;
  //   if (isForced && !!defaultFilterValues) {
  //     setQueryParams({ ...queryParams, filterValues: defaultFilterValues });
  //   } else {
  //     if (!!defaultFilterValues && !isMounted) {
  //       setQueryParams({ ...queryParams, filterValues: defaultFilterValues });
  //     }
  //   }
  // }, [defaultFilterValues, isMounted, state]);

  useEffect(() => {
    if (!noUrl) {
      navigate(`?${exposedQueryString}`);
    }
  }, [exposedQueryString]);

  return {
    data: data?.items || [],
    columns: andtDTableCols,
    isLoading: isFetching,
    paginationProps,
    filterProps,
    hasFilters,
    maxHeight,
    queryString: exposedQueryString,
    filtersOnlyQueryString,
    showHeader,
    emptyRowText,
    stickyHeader,
    selectedRows,
    onSort,
    onSelectRows: (selectedRows: T[]) => {
      onSelectRows?.(selectedRows);
      setSelectedRows(selectedRows);
    },
    refetch: getItems,
    clearSelection,
    onRowClick,
    onRowMouseEnter,
    onRowMouseLeave,
    changeColumns,
  };
}
